<template>
    <div>
      <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
        <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
          <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
        </div>
      </header>
      <div class="container">
        <div class="row justify-content-center" >
          <div class="col-md-8">
            <div class="card mb-2">
              <div class="card-body mb-2">
                <h5 class="text-center mb-2 mt-4">Método de Pago</h5>
                <p style="text-align:center">
                  Completa el pago y obtén tu plan <br> de tratamiento en menos de 24 horas<br><br>
                  ¿Cuál es el metodo de pago<br> de tu preferencia?
                </p>
                <div class="card mb-2">
              <div class="card-body">
                <h5 class=" mb-3">Tarjeta de débito o crédito</h5>
                <button class="btn btn-primary" @click="cardPayment(`${this.$price}`)" style="width:80%">Pagar con tarjeta</button>
              </div>
            </div>
            <div class="card mb-2">
              <div class="card-body">
                <h5 class=" mb-3">Transferencia Interbancaria</h5>
                <router-link :to="{ name: 'WireTransfer', params: { id: this.$route.params.id }}" class="btn btn-primary" style="width:80%">Pagar por transferencia</router-link>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class=" mb-3">Depósito en efectivo</h5>
                <router-link :to="{ name: 'CashPayments', params: { id: this.$route.params.id }}" class="btn btn-primary" style="width:80%">Pagar en efectivo</router-link>
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import { getAuth, onAuthStateChanged } from 'firebase/auth';
  import { mapState, mapActions } from 'vuex';
  import { loadStripe } from '@stripe/stripe-js';
  import axios from 'axios';

  import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

  export default {
    setup() {
    const { track } = useFacebookPixel();


    onMounted(() => {
      track('SubmitApplication', { content_name: 'Create Guest Assessment' });
      track('ViewContent', { content_name: 'Guest Payment Method' });
  
    });

    return {};
  },

    data() {
      return {
        assessment_id: null,
        password:'',
        HeaderLogo: require('@/assets/dermapp-white.png')
      };
    },
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'profileLastName', 'profileBirthday', 'primaryName', 'primaryBirthday']),
    },
    mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          console.log("Usuario autenticado");
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token
          console.log('Token renovado:', idToken);

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            console.log('Token expirado, redirigiendo al login.');
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        console.log('No hay un usuario autenticado.');
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

  this.assessment_id = this.$route.params.id;
  this.password = this.$route.params.password;
  this.userAgent = navigator.userAgent; // Store the user agent

},
methods: {

  ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription' ]),

  async getLocationByIp(ipAddress) {
  const response = await axios.get(`https://ipinfo.io/${ipAddress}/geo`);
  return { city: response.data.city, state: response.data.region, zipCode: response.data.postal };
},

async getIpAddress() {
  const response = await axios.get('https://api64.ipify.org?format=json');
  return response.data.ip;
},

getFbcAndFbp() {
  let fbc, fbp;

  try {
    // Extract _fbc from the cookie
    fbc = document.cookie.split('; ').find(row => row.startsWith('_fbc')).split('=')[1];
  } catch {
    fbc = null;
  }

  try {
    // Extract _fbp from the cookie
    fbp = document.cookie.split('; ').find(row => row.startsWith('_fbp')).split('=')[1];
  } catch {
    fbp = null;
  }

  return { fbc, fbp };
},


async cardPayment(price) {

let { fbp, fbc } = this.getFbcAndFbp();
let ipAddress = await this.getIpAddress();
let { city, state, zipCode } = await this.getLocationByIp(ipAddress);

let userData = {
  email: this.email,
  phone: this.phone,
  name: this.profileName,
  lastName: this.profileLastName,
  sex: this.profileSex,
  userId: this.userId.toString(),
  fbp: fbp,
  fbc: fbc,
  birthday: this.profileBirthday,
  ipAddress: ipAddress,
  userAgent: this.userAgent,
  country: this.country,
  state: state,
  city: city,
  zipCode: zipCode,
}

try {
  // Create user tracking
  await axios.post(`${this.$baseURL}/api/create-user-tracking`, userData);

  // Load Stripe
  const stripe = await loadStripe(`${this.$loadstripepk}`);

  // Create checkout session
  const response = await axios.post(`${this.$baseURL}/api/create-checkout-session`, {
    user_id: this.userId,
    email: this.email,
    price: price, // Ensure this matches what your backend expects
    assessment_id: this.assessment_id,
    payment_method: 'card',
    phone: this.phone,
    patient_name: this.profileName,
  });

  // Redirect to Stripe checkout
  const sessionId = response.data.sessionId;
  stripe.redirectToCheckout({ sessionId });

} catch (error) {
  console.error('Error during payment process:', error);
}
},

},
  };
  </script>

<style scoped>

.header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

  </style>
  