<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
      </div>
      <nav class="d-flex align-items-center justify-content-end" style="width: 100%; text-align:right">
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px;width:auto;margin-right:20px;border: 2px solid white;border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ this.credits }}
        </button>
      </nav>
    </header>
    <div class="container d-flex align-items-center" style="min-height: 100vh; width: 100vw; flex-direction: column;">
    <div class="card-container" style="width: 400px; max-width: 100%;">
      <div v-if="isDataLoaded" class="card mb-3">
        <div class="card-body">
          <h5 class="text-center mb-2 mt-4">Datos de envio</h5>

                <form @submit.prevent="createDeilveryAddress()" style="text-align: left;">
                  <div class="mb-3" >
                    Nombre completo
                      <input type="text" class="form-control" id="name" v-model="this.address.name" placeholder="Ingresa tu nombre completo" required>
                    </div>
                    <div class="mb-3">
                      <label for="phone">Telefono de contacto</label>
                      <input type="text" 
                             class="form-control" 
                             id="phone" 
                             v-model="this.address.phone" 
                             placeholder="Ingresa los 10 dígitos de tu teléfono" 
                             maxlength="10" 
                             inputmode="numeric"
                             @keypress="validateInput($event)"
                             :class="{'border-danger': this.address.phone.length > 0 && this.address.phone.length !== 10}">
                    </div>
                    
                    
                    
                    
                    
                    
                  <div class="mb-3">
                    Calle
                    <input type="text" class="form-control" id="street" v-model="this.address.street" placeholder="Ingresa tu calle" required>
                  </div>
                    <div class="mb-3">
                      Numero de domicilio (exterior e interior)
                      <input type="text" class="form-control" id="house_number" v-model="this.address.number" placeholder="Ej. 204 int 4" required>
                    </div>
                    <div class="mb-3">
                      Codigo postal
                      <input type="text" class="form-control" id="zip_code" v-model="this.address.zip" placeholder="Ingresa tu codigo postal" required>
                    </div>
                    <div class="mb-3">
                      Colonia
                      <input type="text" class="form-control" id="district" v-model="this.address.district" placeholder="Ingresa tu colonia" required>
                    </div>
                    <div class="mb-3">
                      Municipio/Alcaldia
                      <input type="text" class="form-control" id="city" v-model="this.address.city" placeholder="Ingresa tu ciudad" required>
                    </div>
                    <div class="mb-3">
                      Estado
                      <input type="text" class="form-control" id="state" v-model="this.address.state" placeholder="Ingresa tu estado" required>
                    </div>
                    <div class="mb-3">
                      <label for="guidelines">Referencias</label>
                      <input type="text" 
                             class="form-control" 
                             id="guidelines" 
                             v-model="this.address.guidelines" 
                             placeholder="Ej. Casa azul con zaguan rojo" 
                             maxlength="50" 
                             required>
                    </div>
                    <div class="mb-3">
                      <label for="is_between">Entre calles</label>
                      <input type="text" 
                             class="form-control" 
                             id="is_between" 
                             v-model="this.address.is_between" 
                             placeholder="Ej Entre aries y escorpio" 
                             maxlength="50" 
                             required>
                    </div>
  

                    <div style="text-align:center">
                      <button type="submit" class="btn btn-primary" style="width: 80%;">Guardar direccion</button>
                    </div>
                </form>

            
      </div>
    </div>
  </div>
    </div>
  </div>
    
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { mapActions, mapState } from 'vuex';
import useFacebookPixel from '@/composables/useFacebookPixel';

  import axios from 'axios';
  import { onMounted } from 'vue';


export default {
  setup() {
    const { track } = useFacebookPixel();


    onMounted(() => {
      track('ViewContent', {content_name: 'Initiate Pharmacy Checkout' });
      //track('InitiateCheckout', { content_name: 'Initiate pharmacy checkout' });

    });

    return {};
  }, 
  data() {
    return {
      nextChargeDate: '',
      isDataLoaded: false,
      address: {
      id: null,
      user_id: null,
      name: '',
      street: '',
      number: '',
      zip: '',
      district: '',
      city: '',
      state: '',
      guidelines: '',
      is_between: '',
      phone: '',
      },
      mode: 0,
      assessmentId: null,
      HeaderLogo: require('@/assets/dermapp-white.png')
    };
  },
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday']),
    },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          console.log("Usuario autenticado");
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token
          console.log('Token renovado:', idToken);

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            console.log('Token expirado, redirigiendo al login.');
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        console.log('No hay un usuario autenticado.');
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });
      const token = localStorage.getItem('Token');
      this.assessmentId = this.$route.params.id;
      this.address.user_id = this.userId;
        // Fetch the user data
  axios.get(`${this.$baseURL}/api/user-by-assessment`, {
      params: {
        assessment_id: this.assessmentId || this.$route.params.id,
      },
    })
    .then((response) => {
      const data = response.data.data;
      this.updateUserId(data.user.id);
      this.updateEmail(data.user.email);
      this.updatePhone(data.user.phone, data.user.country);
      this.updateCountry(data.user.country);
      this.updateCredits(data.user.credits);
      this.updateReferralCode(data.user.referral_code);
      this.updateProfileName(data.profile.name.split(' ')[0]);

      axios.get(`${this.$baseURL}/api/user-address`, {
      params: {
        user_id: this.userId,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((address_response) => {
      if(address_response.data.success==true){
        this.mode = 1;
        this.address = address_response.data.data;
      }
      
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
      this.isDataLoaded=true;
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });

      
    
  },
  methods: {

    validateInput(event) {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }, 

    ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),
    
    async createDeilveryAddress() {

      if(this.mode == 0) {
        const response = await axios.post(`${this.$baseURL}/api/create-delivery-address`, this.address);
        if (response.status === 200) {
          this.$router.push(`/pharmacy-payment-method/${this.assessmentId}`);
        }
      } else {
        const response = await axios.put(`${this.$baseURL}/api/update-delivery-address`, this.address);
        if (response.status === 200) {
          this.$router.push(`/pharmacy-payment-method/${this.assessmentId}`);
        }
      }
    }
  
  },
};
</script>

<style scoped>

.header {
    margin-top: -5px;
    position: relative;
    width: 100%;
    height: auto;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-link-style {
  text-decoration: none;
  color: inherit; /* Ensure that the text color remains unchanged */
}

  </style>
