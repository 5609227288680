<template>
  <div>
    <header class="header d-flex align-items-center justify-content-between" style="width: 100%; padding: 20px;">
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:60px;"/>
      <nav>
          <!-- <button class="nav-item">Puntos: {{ this.credits }}</button> -->
        </nav>
    </header>
        
    <div class="container">
      <div class="row justify-content-center" >
        <div  v-if='this.isDataLoaded' class="col-md-8" style="margin-top: 60px;">
          <div  class="card mb-2">
            <div class="card-body">
              <h5 class="card-title" style="margin-top:10px">Diagnóstico: {{ this.diagnosis.diagnosis }}</h5>
              <div class="card mb-2 mt-4" v-if="JSON.parse(followup.recommendations).length">
                <button class="h5" @click="toggleRecommendations" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Recomendaciones generales
                  <img :src="collapseIconUrl" alt="Collapse" />
                </button> 
            <div class="card-body" v-if="!isRecommendationsCollapsed">
              <div class="card-text" style="text-align: left;margin-right: 20px;">
                <ul v-for="(recommendation,index) in JSON.parse(followup.recommendations)" :key="index">
                  <li>
                    {{recommendation}}
                  </li>
                </ul>
              </div>
            </div>
          </div> 
          <div class="card mb-2">
            <button class="h5" @click="toggleComments" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Comentarios de seguimiento
              <img :src="collapseIconUrl" alt="Collapse" />
            </button> 
        <div class="card-body" v-if="!isCommentsCollapsed">
          <div class="card-text" style="text-align: left;margin-right: 20px;margin-left: 10px;">
          {{ this.followup.comments }}
          </div>
        </div>
      </div>  
              <div class="card mb-2" >
                <button class="h5" @click="toggleTreatment" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Plan de tratamiento
                  <img :src="collapseIconUrl" alt="Collapse" />
                </button> 
            <div class="card-body" v-if="!isTreatmentCollapsed">
              <div v-if="this.assessment.status == 0">
                <p>Tu tratamiento esta listo!</p>
                <p>Completa el pago para desbloquearlo</p>
                <router-link :to="{ name: 'PaymentMethod', params: { id: this.assessment.id }}" class="btn btn-primary">Completar Pago</router-link>
              </div>
              <div v-else>
                <div v-if="this.treatment_plan.length > 0" class="card mb-2">
                  <h5 style="margin-top: 20px;">Tratamiento Principal</h5>
                  
                  <div style="text-align:left; margin-right: 20px;">
                    <div v-for="(product, index) in followup_treatment_plan" :key="index" class="mb-4">
                      <p style="margin-left: 10px; font-size:12px">{{ index + 1 }}. </p> 
                  
                        <div class="d-flex">
                            
                            <!-- Flex container for equal width cards -->
                            <div class="d-flex flex-grow-1">
                                <!-- Main Treatment Product Name -->
                                <div class="card flex-fill" style="width:50%; margin-left:10px; margin-right:10px;">
                                    <div class="card-body">
                                        <p class="card-title" style="font-size: 10px">{{ product.name.toUpperCase() }}</p>
                                    </div>
                                </div>
                                <!-- Omicron Product Name, displayed only if exists -->
                                <div class="card flex-fill" v-if="product.has_omicron==1" style="width:50%; ">
                                    <div class="card-body">
                                        <p class="card-title" style="font-size: 10px">{{ product.omicron.name.toUpperCase() }}</p>
                                    </div>
                                </div>
                                <div class="card flex-fill" v-else style="width:50%; ">
                                  <div class="card-body">
                                      <p class="card-title" style="font-size: 10px">SIN OPCION MAGISTRAL</p>
                                  </div>
                              </div> 
                            </div>
                        </div>
                        <div class="d-flex justify-content-between" style="width:100%; padding-left:30px; padding-right:25px;">
                          <p  style="font-size: 10px">opción comercial</p>
                          <p  style="font-size: 10px">opción magistral</p>
                         </div> 
                        
                        <div v-for="(indication, index) in JSON.parse(product.indications)" :key='index' style="margin-left:20px; font-size:12px">
                           <br> {{ indication.indication }} <br>
                           <span class="days-row" v-if="indication.day.length==7">
                            Todos los dias
                          </span>
                          <span v-else>
                            <span class="days-row" v-for="(day, index) in indication.day" :key="index">
                              <span v-if="index==indication.day.length-1 && indication.day.length>1"> y </span>
              
                              {{ parseDay(day) }}
                            </span>
                          </span>
                          <span>
                          a las {{ indication.time.hour }} {{ indication.time.suffix }} <br></span>
                          Cada {{ indication.frequency.amount }} {{ parseDuration(indication.frequency.suffix) }} durante {{ indication.duration.amount }} {{ parseDuration(indication.duration.suffix) }}
                        </div>
                    </div>
                </div>
            </div>
            <h5 style="margin-top:20px;">Tratamiento Opcional</h5>
            <div style="text-align:left; margin-top: 20px;margin-right: 20px;">
              <div v-for="(product, index) in followup_extended_treatment_plan" :key="index" class="mb-4">
               <p style="margin-left: 10px;font-size:12px">{{ index + 1 + treatment_plan.length}}. <br> </p> 
                  <div class="d-flex">
                      
                      <!-- Flex container for equal width cards -->
                      <div class="d-flex flex-grow-1">
                          <!-- Main Treatment Product Name -->
                          <div class="card flex-fill" style="width:50%; margin-left:15px; margin-right:10px;">
                              <div class="card-body">
                                  <p class="card-title" style="font-size: 10px">{{ product.name.toUpperCase() }}</p>
                              </div>
                          </div>
                          <!-- Omicron Product Name, displayed only if exists -->
                          <div class="card flex-fill" v-if="product.has_omicron==1" style="width:50%; ">
                              <div class="card-body">
                                  <p class="card-title" style="font-size: 10px">{{ product.omicron.name.toUpperCase() }}</p>
                              </div>
                          </div>
                          <div class="card flex-fill" v-else style="width:50%; ">
                            <div class="card-body">
                                <p class="card-title" style="font-size: 10px">SIN OPCION MAGISTRAL</p>
                            </div>
                        </div> 
                      </div>
              </div>
              <div class="d-flex justify-content-between" style="width:100%; padding-left:30px; padding-right:25px;">
                <p  style="font-size: 10px">opción comercial</p>
                <p  style="font-size: 10px">opción magistral</p>
               </div> 
              <div v-for="(indication, index) in JSON.parse(product.indications)" :key='index' style="margin-left:20px;font-size:12px">
                <br> {{ indication.indication }} <br>
                <span class="days-row" v-if="indication.day.length==7">
                  Todos los dias
                </span>
                <span v-else>
                  <span class="days-row" v-for="(day, index) in indication.day" :key="index">
                    <span v-if="index==indication.day.length-1 && indication.day.length>1"> y </span>
    
                    {{ parseDay(day) }}
                  </span>
                </span>
                <span>
                a las {{ indication.time.hour }} {{ indication.time.suffix }} <br></span>
                Cada {{ indication.frequency.amount }} {{ parseDuration(indication.frequency.suffix) }} durante {{ indication.duration.amount }} {{ parseDuration(indication.duration.suffix) }}
              </div>
          </div>
      </div>
            </div>
          </div>
        </div> 

        <div class="card mb-2" >
          <button class="h5" @click="togglePharmacy" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Cotiza tu tratamiento
            <img :src="collapseIconUrl" alt="Collapse" />
          </button> 
      <div class="card-body" v-if="!isPharmacyCollapsed">
        <div v-if="this.assessment.status >= 1 || this.profileSubscription == 1">

                      <!-- Cotizacion magistral -->
                      <div class="card mb-2" >
                        <button class="h5" @click="toggleFormulasCart" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Formulas magistrales
                          <img :src="collapseIconUrl" alt="Collapse" />
                        </button> 
                    <div v-if="!isFormulasCartCollapsed">
                      <ul v-for="(product, index) in this.followup_treatment_plan" :key="index" style="margin-right: 20px; text-align:left; font-size:12px">
                        <div>
                          <span v-if="product.has_omicron==1">{{ index + 1 }}. {{ product.omicron.name.toUpperCase() }}</span>
                          <span v-else-if="product.sku!='0000000000' && product.sku!='0'">{{ index + 1 }}. {{ product.name.toUpperCase() }}</span>
                          <span v-else>{{ index + 1 }}. {{ product.name.toUpperCase() }} - NO DISPONIBLE</span>

                              <div style="display: flex; align-items: center; margin-top: 20px;">
                            <div v-if="product.has_omicron==1" class="input-group" style="width:100px; margin-right: 10px;">
                              <button class="btn btn-primary" type="button" @click="decreaseQuantity(index)">-</button>
                              <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                              <button class="btn btn-primary" type="button" @click="increaseQuantity(index)">+</button>  
                            </div>
                            <div v-else-if="product.sku!='0000000000' && product.sku!='0'" class="input-group" style="width:100px; margin-right: 10px;">
                              <button class="btn btn-primary" type="button" @click="decreaseQuantity(index)">-</button>
                              <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                              <button class="btn btn-primary" type="button" @click="increaseQuantity(index)">+</button>  
                            </div>
                            <div v-if="product.has_omicron==1" class="input-group" style="width:100px;">
                              <select v-if="formatDoses(product.omicron.dose).length>1" id="dose" v-model="product.omicron.index" class="form-control custom-select">
                                <option v-for="(dose, index) in formatDoses(product.omicron.dose)" :key="index" :value="index">{{ dose }}</option>
                              </select>
                              <div v-else style="font-size:16px">
                                {{formatDoses(product.omicron.dose)[0]}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </ul>
                      
                      
                        <ul v-for="(product, index) in this.followup_extended_treatment_plan" :key='index' style="margin-right: 20px; text-align:left; font-size:12px">
                          <div>
                            <span v-if="product.has_omicron==1">{{ index + 1 + this.followup_treatment_plan.length}}. {{ product.omicron.name.toUpperCase() }}</span>
                            <span v-else-if="product.sku!='0000000000' && product.sku!='0'">{{ index + 1 + this.followup_treatment_plan.length}}. {{ product.name.toUpperCase() }}</span>
                            <span v-else>{{ index + 1 + this.treatment_plan.length}}. {{ product.name.toUpperCase() }} - NO DISPONIBLE</span>
                            <div style="display: flex; align-items: center; margin-top: 20px;">
                            <div v-if="product.has_omicron==1" class="input-group" style="width:100px; margin-right: 10px;">
                              <button class="btn btn-primary" type="button" @click="decreaseExtendedQuantity(index)">-</button>
                              <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                              <button class="btn btn-primary" type="button" @click="increaseExtendedQuantity(index)">+</button>  
                            </div>
                            <div v-else-if="product.sku!='0000000000' && product.sku!='0'" class="input-group" style="width:100px; margin-right: 10px;">
                              <button class="btn btn-primary" type="button" @click="decreaseExtendedQuantity(index)">-</button>
                              <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                              <button class="btn btn-primary" type="button" @click="increaseExtenededQuantity(index)">+</button>  
                            </div>
                              <div v-if="product.has_omicron==1" class="input-group" style="width:100px;">
                                <select v-if="formatDoses(product.omicron.dose).length>1" id="dose" v-model="product.omicron.index" class="form-control custom-select">
                                  <option v-for="(dose, index) in formatDoses(product.omicron.dose)" :key="index" :value="index">{{ dose }}</option>
                                </select>
                                <div v-else style="font-size:16px">
                                  {{formatDoses(product.omicron.dose)[0]}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </ul>
                      
                      </div>
                      <p style="margin-right: 20px; margin-left: 10px;">
                        Total:
                        ${{ this.calculateFormulasTotal() }} MXN <br><br>
                        <button class="btn btn-primary" type="button" @click="createPharmacyCheckout(0)">Pedir a Domicilio</button>
                        <span style="color:red;margin-left:20px">{{ pharmacyButtonText(this.formulas_total) }}</span> <br>
                        </p>
                    </div>

                                <!-- Cotizacion farmacia -->
          <div class="card mb-2" >
            <button class="h5" @click="togglePharmacyCart" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Productos comerciales
              <img :src="collapseIconUrl" alt="Collapse" />
            </button> 
        <div v-if="!isPharmacyCartCollapsed">
            <ul v-for="(product, index) in this.followup_treatment_plan" :key='index' style="margin-right: 20px; text-align:left; font-size:12px">
              <div>
                <span v-if="product.sku!='0000000000' && product.sku!='0'">{{ index + 1 }}. {{ product.name.toUpperCase() }}</span>
                <span v-else-if="product.has_omicron==1">{{ index + 1 }}. {{ product.omicron.name.toUpperCase() }}</span>
                <span v-else>{{ index + 1 }}. {{ product.name.toUpperCase() }} - NO DISPONIBLE</span>

                    <div style="display: flex; align-items: center; margin-top: 20px;">
                      <div v-if="product.sku!='0000000000' && product.sku!='0'" class="input-group" style="width:100px; margin-right: 10px;">
                        <button class="btn btn-primary" type="button" @click="decreaseQuantity(index)">-</button>
                        <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                        <button class="btn btn-primary" type="button" @click="increaseQuantity(index)">+</button>  
                      </div>
                      <div v-else-if="product.has_omicron==1" class="input-group" style="width:100px; margin-right: 10px;">
                        <button class="btn btn-primary" type="button" @click="decreaseQuantity(index)">-</button>
                        <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                        <button class="btn btn-primary" type="button" @click="increaseQuantity(index)">+</button>  
                      </div>
                      <div v-if="!(product.sku!='0000000000' && product.sku!='0') && product.has_omicron==1" class="input-group" style="width:100px;">
                        <select v-if="formatDoses(product.omicron.dose).length>1" id="dose" v-model="product.omicron.index" class="form-control custom-select">
                          <option v-for="(dose, index) in formatDoses(product.omicron.dose)" :key="index" :value="index">{{ dose }}</option>
                        </select>
                        <div v-else style="font-size:16px">
                          {{formatDoses(product.omicron.dose)[0]}}
                        </div>
                      </div>
                </div>
              </div>
            </ul>
            <ul v-for="(product, index) in this.followup_extended_treatment_plan" :key='index' style="margin-right: 20px;text-align:left; font-size:12px">
              <div>
                <span v-if="product.sku!='0000000000' && product.sku!='0'">{{ index + 1 + this.followup_treatment_plan.length}}. {{ product.name.toUpperCase() }}</span>
                <span v-else-if="product.has_omicron==1">{{ index + 1 + this.followup_treatment_plan.length }}. {{ product.omicron.name.toUpperCase() }}</span>
                <span v-else>{{ index + 1 + this.treatment_plan.length}}. {{ product.name.toUpperCase() }} - NO DISPONIBLE</span>

                    <div style="display: flex; align-items: center; margin-top: 20px;">
                      <div v-if="product.sku!='0000000000' && product.sku!='0'" class="input-group" style="width:100px; margin-right: 10px;">
                        <button class="btn btn-primary" type="button" @click="decreaseExtendedQuantity(index)">-</button>
                        <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                        <button class="btn btn-primary" type="button" @click="increaseExtendedQuantity(index)">+</button>  
                      </div>
                      <div v-else-if="product.has_omicron==1" class="input-group" style="width:100px; margin-right: 10px;">
                        <button class="btn btn-primary" type="button" @click="decreaseExtendedQuantity(index)">-</button>
                        <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                        <button class="btn btn-primary" type="button" @click="increaseExtenededQuantity(index)">+</button>  
                      </div>
                      <div v-if="!(product.sku!='0000000000' && product.sku!='0') && product.has_omicron==1" class="input-group" style="width:100px;">
                        <select v-if="formatDoses(product.omicron.dose).length>1" id="dose" v-model="product.omicron.index" class="form-control custom-select">
                          <option v-for="(dose, index) in formatDoses(product.omicron.dose)" :key="index" :value="index">{{ dose }}</option>
                        </select>
                        <div v-else style="font-size:16px">
                          {{formatDoses(product.omicron.dose)[0]}}
                        </div>
                      </div>
                </div>
              </div>
            </ul>
          
          </div>
          <p style="margin-right: 20px; margin-left: 10px;">
            Total: 
            ${{ this.calculatePharmacyTotal() }} MXN <br><br>
            <button class="btn btn-primary" type="button" @click="createPharmacyCheckout(2)">Pedir a Domicilio</button>
            <span style="color:red;margin-left:20px">{{ pharmacyButtonText(this.calculatePharmacyTotal()) }}</span> <br>
            </p>
        </div>
        
        
        
          <!-- Cotizacion custom -->
          <div class="card mb-2" >
            <button class="h5" @click="toggleCustomCart" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Personaliza tu pedido
              <img :src="collapseIconUrl" alt="Collapse" />
            </button> 
        <div v-if="!isCustomCartCollapsed">
          <div style="text-align:left; margin-right: 20px;">
            <div v-for="(product, index) in this.followup_treatment_plan" :key='index' style="text-align:left">
              <div>
                <p style="font-size:12px; margin-left:10px;">{{ index + 1 }}. </p> 
          
                <div class="d-flex">
                    
                    <!-- Flex container for equal width cards -->
                    <div class="d-flex flex-grow-1">
                        <!-- Main Treatment Product Name -->
                        <div class="card flex-fill" 
                        v-if="product.sku!='0000000000' && product.sku!='0'" 

                            :class="{'bg-primary text-white': (product.has_omicron == 1 && product.omicron_selected != 1) || (product.has_omicron != 1), '': product.has_omicron == 1 && product.omicron_selected == 1}" 
                            style="width:50%; margin-left:10px; margin-right:10px;" 
                            @click="selectOption(product, 0)">
                            <div class="card-body">
                                <p class="card-title" style="font-size: 10px">{{ product.name.toUpperCase() }}</p>
                            </div>
                        </div>
                        <div class="card flex-fill" v-else style="width:50%; margin-left:10px; margin-right:10px;">
                          <div class="card-body">
                              <p class="card-title" style="font-size: 10px">{{ product.name.toUpperCase() }}<br>SIN OPCION COMERCIAL</p>
                          </div>
                      </div>
                        <!-- Omicron Product Name, displayed only if exists -->
                        <div class="card flex-fill" 
                            v-if="product.has_omicron==1" 
                            :class="{'bg-primary text-white': product.omicron_selected == 1, '': product.omicron_selected != 1}" 
                            style="width:50%;" 
                            @click="selectOption(product, 1)">
                            <div class="card-body">
                                <p class="card-title" style="font-size: 10px">{{ product.omicron.name.toUpperCase() }}</p>
                            </div>
                        </div>
                        <div class="card flex-fill" v-else style="width:50%;">
                            <div class="card-body">
                                <p class="card-title" style="font-size: 10px">SIN OPCION MAGISTRAL</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex; align-items: center; margin-top: 20px;  margin-left:10px; margin-bottom:20px;">
                  <div v-if="product.sku!='0000000000' && product.sku!='0'" class="input-group" style="width:100px; margin-right: 10px;">
                    <button class="btn btn-primary" type="button" @click="decreaseQuantity(index)">-</button>
                    <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                    <button class="btn btn-primary" type="button" @click="increaseQuantity(index)">+</button>  
                  </div>
                  <div v-else-if="product.has_omicron==1" class="input-group" style="width:100px; margin-right: 10px;">
                    <button class="btn btn-primary" type="button" @click="decreaseQuantity(index)">-</button>
                    <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                    <button class="btn btn-primary" type="button" @click="increaseQuantity(index)">+</button>  
                  </div>
                  <div v-if="product.omicron_selected==1 && product.has_omicron==1" class="input-group" style="width:100px;">
                    <select v-if="formatDoses(product.omicron.dose).length>1" id="dose" v-model="product.omicron.index" class="form-control custom-select">
                      <option v-for="(dose, index) in formatDoses(product.omicron.dose)" :key="index" :value="index">{{ dose }}</option>
                    </select>
                    <div v-else style="font-size:16px">
                      {{formatDoses(product.omicron.dose)[0]}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(product, index) in this.followup_extended_treatment_plan" :key='index' style="text-align:left">
              <div>
                <p style="margin-left: 10px;font-size:12px">{{ index + 1 + this.followup_treatment_plan.length}}. <br> </p> 
              <div class="d-flex">
                  
                  <!-- Flex container for equal width cards -->
                  <div class="d-flex flex-grow-1">
                      <!-- Main Treatment Product Name -->
                      <div class="card flex-fill" 
                      v-if="product.sku!='0000000000' && product.sku!='0'" 

                          :class="{'bg-primary text-white': (product.has_omicron == 1 && product.omicron_selected != 1) || (product.has_omicron != 1), '': product.has_omicron == 1 && product.omicron_selected == 1}" 
                          style="width:50%; margin-left:10px; margin-right:10px;" 
                          @click="selectOption(product, 0)">
                          <div class="card-body">
                              <p class="card-title" style="font-size: 10px">{{ product.name.toUpperCase() }}</p>
                          </div>
                      </div>
                      <div class="card flex-fill" v-else style="width:50%; margin-left:10px; margin-right:10px;">
                        <div class="card-body">
                            <p class="card-title" style="font-size: 10px">{{ product.name.toUpperCase() }}<br>SIN OPCION COMERCIAL</p>
                        </div>
                    </div>
                      <!-- Omicron Product Name, displayed only if exists -->
                      <div class="card flex-fill" 
                          v-if="product.has_omicron==1" 
                          :class="{'bg-primary text-white': product.omicron_selected == 1, '': product.omicron_selected != 1}" 
                          style="width:50%;" 
                          @click="selectOption(product, 1)">
                          <div class="card-body">
                              <p class="card-title" style="font-size: 10px">{{ product.omicron.name.toUpperCase() }}</p>
                          </div>
                      </div>
                      <div class="card flex-fill" v-else style="width:50%;">
                          <div class="card-body">
                              <p class="card-title" style="font-size: 10px">SIN OPCION MAGISTRAL</p>
                          </div>
                      </div>
                  </div>
                  </div>
                  <div style="display: flex; align-items: center; margin-top: 20px; margin-left:10px; margin-bottom:20px;">
                    <div v-if="product.sku!='0000000000' && product.sku!='0'" class="input-group" style="width:100px; margin-right: 10px;">
                      <button class="btn btn-primary" type="button" @click="decreaseExtendedQuantity(index)">-</button>
                      <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                      <button class="btn btn-primary" type="button" @click="increaseExtendedQuantity(index)">+</button>  
                    </div>
                    <div v-else-if="product.has_omicron==1" class="input-group" style="width:100px; margin-right: 10px;">
                      <button class="btn btn-primary" type="button" @click="decreaseExtendedQuantity(index)">-</button>
                      <input type="text" class="form-control" id="quantity" v-model="product.quantity">
                      <button class="btn btn-primary" type="button" @click="increaseExtenededQuantity(index)">+</button>  
                    </div>
                    <div v-if="product.omicron_selected==1 && product.has_omicron==1" class="input-group" style="width:100px;">
                      <select v-if="formatDoses(product.omicron.dose).length>1" id="dose" v-model="product.omicron.index" class="form-control custom-select">
                        <option v-for="(dose, index) in formatDoses(product.omicron.dose)" :key="index" :value="index">{{ dose }}</option>
                      </select>
                      <div v-else style="font-size:16px">
                        {{formatDoses(product.omicron.dose)[0]}}
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
            
          </div>
          <p style="margin-right: 20px; margin-left: 10px;">
            Total:
            ${{ this.calculateCustomTotal() }} MXN <br><br>
            <button class="btn btn-primary" type="button" @click="createPharmacyCheckout(1)">Pedir a Domicilio</button>
            <span style="color:red;margin-left:20px">{{ pharmacyButtonText(this.calculateCustomTotal()) }}</span> <br>
            </p>
        </div>
      </div>
      </div>
    </div>



    <div class="card mb-2" v-if="JSON.parse(followup.exams).length" >
      <button class="h5" @click="toggleExams" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Exámenes médicos
        <img :src="collapseIconUrl" alt="Collapse" />
      </button> 
  <div class="card-body" v-if="!isExamsCollapsed">
    <div class="card-text" style="text-align: left;margin-right: 20px;">
      <ul v-for="(exam,index) in JSON.parse(followup.exams)" :key="index">
        <li>
          {{exam}}
        </li>
      </ul>
    </div>
    <button style="margin-bottom: 20px" class="btn btn-primary" type="button" @click="openExamedi()">Pedir a Domicilio</button>
    <br>*Disponible en CDMX y Estado de México
  </div>
</div>    
<div class="card mb-2">
  <button class="h5" @click="toggleAssessmentsData" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Consultas anteriores
    <img :src="collapseIconUrl" alt="Collapse" />
  </button> 
  <div class="card-body" v-if="!isAssessmentsDataCollapsed">
    <div class="card mb-2">
      <div class="card-body">
        <div class="card-text row d-flex align-items-center justify-content-center" style="text-align: center;">
        <div class="col 6 col-md-6">{{ formatDate(assessment.created_at) }}</div>
          <div class="col 6 col-md-6">
            <button class="btn btn-primary equal-width"  style="width: 100%; height: 40px;" @click="openAssessment(assessment.id,0)">Ver</button>
          </div>
        </div>
      </div>
    </div>
      <div class="card mb-2" v-for="(assessment, index) in assessment.previous_assessments" :key="index">
        <div class="card-body">
          <div class="card-text row d-flex align-items-center justify-content-center" style="text-align: center;">
            <div class="col 6 col-md-6">{{ formatDate(assessment.created_at) }}</div>
              <div class="col 6 col-md-6">
                <button class="btn btn-primary equal-width"  style="width: 100%; height: 40px;" @click="openAssessment(assessment.id,1)">Ver</button>
              </div>
            </div>
      </div>
      </div>
    </div>
  </div>
  <div class="card mb-2">
    <button class="h5" @click="toggleReview" style="background: none; border: none; margin-top:10px; margin-bottom:10px">Ayudanos a mejorar
        <img :src="collapseIconUrl" alt="Collapse" />
    </button> 
    <div class="card-body" v-if="!isReviewCollapsed">
        <div class="card-text" style="text-align: left; margin-right: 20px;">
            <p>Por favor califica esta respuesta:</p>
            <div>
                <span v-for="n in 5" :key="n">
                    <i class="fa fa-star" style="margin-right:5px" @click="setRating(n)" :class="{ 'active': n <= rating }"></i>
                </span>
            </div>
            <textarea style="margin-top: 20px" v-model="review_comment" placeholder="¿Cómo podemos mejorar?"></textarea>
        </div>
        <button style="margin-bottom: 20px" class="btn btn-primary" type="button" @click="submitReview">Enviar</button>
    </div>
</div>
              <h5 class="card-title" style="margin-top:20px"><br>Próxima consulta:<br>{{ formatFollowupDate() }}</h5>   
              <p style="color: red; margin-top:20px">
                Tu experiencia es importante para nosotros<br><br>
                Por favor contactanos si tienes dudas o si no estas conforme tu diagnostico<br>
              </p>
                <button class="btn btn-primary mb-4" type="button" @click="openWhatsapp(1)">Contactar Soporte</button>            
            </div>
          </div>             
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  import axios from 'axios';

  import useFacebookPixel from '@/composables/useFacebookPixel';

  import { onMounted } from 'vue';

  
  export default {
    setup() {
    const { track } = useFacebookPixel(); 


    onMounted(() => {
      track('InitiateCheckout', {content_name: 'Followup Treatment' });
      track('ViewContent', {content_name: 'Followup Assessment' });

    });

    return {};
  },

    
    data() {
      return {
        custom_total: null,
        patent_total: null,
        compound_total:null ,
        pharmacy_total: null,
        formulas_total: null,
        isReviewCollapsed: true,
        rating: 0,
        review_comment: '',
        diagnosisId: null,
        followupId: null,
        previous_assessments: [],
        drugs: [],
        assessment: [],
        followup_assessment:[],
        diagnosis: [],
        followup: [],
        treatment: [],
        followup_treatment: [],
        current_treatment:[],
        diagnosis_struct: {
          assessment_id: null,
          diagnosis: '',
          explanation: '',
          prognosis: '',
          followup: '',
          recommendations: [],
          exams:[],
          comments: '',
          followup_date: {
            amount: '1',
            suffix: 'Months'
          },
      },
      followup_struct: {
          followup_assessment_id: null,
          followup: '',
          recommendations: [],
          exams:[],
          comments: '',
          followup_date: {
            amount: '1',
            suffix: 'Months'
          },
          deleted_products:[],
      },
      recommendation: '',
      exam: '',
      treatment_plan: [],
      extended_treatment_plan: [],
      followup_treatment_plan: [],
      followup_extended_treatment_plan: [],
        cycle: {
          indication: '',
          day: 'Monday',
          time: {
            hour: '8',
            suffix: 'am',
          },
          frequency: {
            amount: '12',
            suffix: 'Hours',
          },
          duration: {
            amount: '1',
            suffix: 'Months'
          },
        },
        num_cycles: 1,
        edit_mode: 0,
        edit_index: null,
        isDataLoaded: false,
        assessmentsLoaded: false,
        activeIndex: 0,
        HeaderLogo: require('@/assets/dermapp-white.webp'),
        isExplanationCollapsed: true,
        isPrognosisCollpased: true,
        isImagesDataCollapsed: true,
        isRecommendationsCollapsed: true,
        isCommentsCollapsed: true,
        isExamsCollapsed: true,
        isTreatmentCollapsed: true,
        isFollowupCollapsed:true,
        isPharmacyCollapsed:true,
        isCustomCartCollapsed: true,
        isPharmacyCartCollapsed: true,
        isFormulasCartCollapsed: true,
        isAssessmentsDataCollapsed:true,



      collapseIconUrl: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z'/></svg>",
    
      };
    },
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday', 'profileSubscription']),
    },

    mounted() {
      const token = localStorage.getItem('Token');
  // Fetch the user data
      axios.get(`${this.$baseURL}/api/assessment-data`, {
      params: {
        assessment_id: this.$route.params.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
      this.assessment = response.data.data;
      this.getAssessmentFiles();

      axios.get(`${this.$baseURL}/api/diagnosis-data`, {
      params: {
        assessment_id: this.$route.params.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((diagnosis_response) => {
      this.diagnosis = diagnosis_response.data.data;
      this.getDiagnosisTreatment();
      axios.get(`${this.$baseURL}/api/get-latest-followup-assessment-v2`, {
      params: {
        assessment_id: this.$route.params.id,
        mode: this.assessment.status,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((followup_response) => {
      this.followup_assessment = followup_response.data.data;
      axios.get(`${this.$baseURL}/api/followup-diagnosis-data`, {
      params: {
        followup_assessment_id: this.followup_assessment.id,
      },      
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((followup_data_response) => {
      this.followup = followup_data_response.data.data;
      this.getFollowupDiagnosisTreatment();
      this.followup_assessment = followup_response.data.data;
      axios.get(`${this.$baseURL}/api/get-followup-assessments`, {
      params: {
        assessment_id: this.$route.params.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        },
    })
    .then((previous_response) => {
      this.assessment.previous_assessments = previous_response.data.data;
      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching followup diagnosis data:", error);
    }); 
    })
    .catch((error) => {
      console.error("Error fetching followup diagnosis data:", error);
    }); 
    })
    .catch((error) => {
      console.error("Error fetching followup data:", error);
    }); 
    })
    .catch((error) => {
      console.error("Error fetching diagnosis data:", error);
    });
    })
    .catch((error) => {
      console.error("Error fetching asessment data:", error);
    });

    
},
    methods: {
      
      toggleReview() {
            this.isReviewCollapsed = !this.isReviewCollapsed;
        },
        setRating(n) {
            this.rating = n;
        },
        submitReview() {
          const updatedReview = {
            followup_diagnosis_id: this.followup.id,
            rating: this.rating,
            review_comment: this.review_comment
          }

            // Make a POST request to your API endpoint to create the assessment
            axios.put(`${this.$baseURL}/api/update-followup-review`, updatedReview);
            alert("Gracias por tu retroalimentacion!");
        },

      openAssessment(id,isFollowup) {
    if(isFollowup==0){
      let url = `${this.$baseURLPacientes}/assessment-diagnosis/${id}`;
    window.open(url, '_blank');
    } else {
      let url = `${this.$baseURLPacientes}/followup-assessment-diagnosis/${id}`;
    window.open(url, '_blank');
    }
    
  },

  openWhatsapp(num) {
        if(num==0){
          let url = 'https://api.whatsapp.com/send?phone=528140052978&text=Hola%2C%20me%20gustaria%20cotizar%20formulas%20magistrales%20para%20mi%20tratamiento';
            window.open(url, '_blank');
        } else {
          let url = 'https://api.whatsapp.com/send?phone=528140052978&text=Hola%2C%20tengo%20una%20duda%20o%20comentario%20sobre%20mi%20diagnostico';
            window.open(url, '_blank');
        }
            
        },

      formatFollowupDate() {
    const createdDate = new Date(this.followup.created_at);
    const followupDate = JSON.parse(this.followup.followup_date); // ensure this is a JSON object
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    if(followupDate.suffix === "Months") {
        createdDate.setMonth(createdDate.getMonth() + parseInt(followupDate.amount));
    } else if(followupDate.suffix === "Weeks") {
        createdDate.setDate(createdDate.getDate() + (parseInt(followupDate.amount) * 7));
    } 

    return createdDate.toLocaleDateString('es-ES', options);
},

      openExamedi(){
        window.open(`https://sdk.examedi.com/dermapp`, '_blank');
      },

      increaseQuantity(index){
        this.followup_treatment_plan[index].quantity ++;
      },

      decreaseQuantity(index){
        if(this.followup_treatment_plan[index].quantity != 0){
          this.followup_treatment_plan[index].quantity --;
        }
      },

      increaseExtendedQuantity(index){
        this.followup_extended_treatment_plan[index].quantity ++;
      },

      decreaseExtendedQuantity(index){
        if(this.followup_extended_treatment_plan[index].quantity != 0){
          this.followup_extended_treatment_plan[index].quantity --;
        }
      },

      
      selectOption(product, option) {
        // Set product.omicron_selected based on which card was clicked
        product.omicron_selected = option;
    },


    calculateCustomTotal(){
        let total = 0;
        this.followup_treatment_plan.forEach(treatment => {
          if(treatment.has_omicron==1 && treatment.omicron_selected==1){

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;
          
          } else {

            total = total + treatment.quantity*treatment.priceIVA/100;
          
          }
          
        });
        this.followup_extended_treatment_plan.forEach(treatment => { 
          if(treatment.has_omicron==1 && treatment.omicron_selected==1){

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;
          
          } else {

            total = total + treatment.quantity*treatment.priceIVA/100;
          
          }
        });
        total = total + 1.50;
        this.custom_total = (total*100).toFixed(2);
        return this.custom_total
      },

      calculatePharmacyTotal(){
        let total = 0;

        
        this.followup_treatment_plan.forEach(treatment => {

          if(treatment.sku!='0000000000' && treatment.sku!='0'){

          total = total + treatment.quantity*treatment.priceIVA/100;

          } else if (treatment.has_omicron==1) {

          total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;

          }
          
        });
        this.followup_extended_treatment_plan.forEach(treatment => { 
          
          if(treatment.sku!='0000000000' && treatment.sku!='0'){

            total = total + treatment.quantity*treatment.priceIVA/100;

          } else if (treatment.has_omicron==1) {

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;

          }

        });
        total = total + 1.50;
        this.pharmacy_total = (total*100).toFixed(2);
        return this.pharmacy_total
      },

      calculateFormulasTotal(){
        let total = 0;
        this.followup_treatment_plan.forEach(treatment => {
          

          if(treatment.has_omicron==1){

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;
          
           } else if (treatment.sku!='0000000000' && treatment.sku!='0') {

            total = total + treatment.quantity*treatment.priceIVA/100;
          
          }
          
        });
        this.followup_extended_treatment_plan.forEach(treatment => { 
          
          if(treatment.has_omicron==1){

            total = total + treatment.quantity*JSON.parse(treatment.omicron.price_iva)[treatment.omicron.index]/100;

           } else if (treatment.sku!='0000000000' && treatment.sku!='0') {

            total = total + treatment.quantity*treatment.priceIVA/100;

            }
         
        });
        total = total + 1.50;
        this.formulas_total = (total*100).toFixed(2);
        return this.formulas_total
      },

      formatDate(dateString) {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    },

    pharmacyButtonText(total){
      if(total>=1100){
        return "Envio incluido"
      } else {
        return "Envio incluido"
      }
    },

    formatDoses(doses) {
  
  // Check if doses is undefined and set it to an empty array
  if (doses === undefined) {
    doses = [];
  }

  // If doses is a string, parse it
  if (typeof doses === 'string') {
    // Remove the first and last characters (the square brackets)
    doses = doses.slice(1, -1);
    // Split the string by commas and trim any extra spaces from each item
    doses = doses.split(',').map(item => item.trim());
  }

  // Check if the resulting doses array is empty
  if (doses.length === 0) {
    doses = ['tamaño único'];
  }

  return doses;
},

async createPharmacyCheckout(mode) {
        let checkout = {};
        let products = [];
      

        if(mode == 0) {
          this.followup_treatment_plan.forEach(product => {
            let temp_product = {
          name: '',
          sku: '',
          sku_magistral: '',
          quantity: null,
          price: null,
          priceIVA: null, 
          dose: '',
          }
            if(product.quantity>0){
              if(product.has_omicron==1){
              temp_product.name = product.omicron.name;
              temp_product.sku = product.omicron.sku;
              temp_product.sku_magistral = product.omicron.sku;
              temp_product.quantity = product.quantity;
              temp_product.price = JSON.parse(product.omicron.price)[product.omicron.index].toString();
              temp_product.priceIVA = JSON.parse(product.omicron.price_iva)[product.omicron.index].toString();
              temp_product.dose = this.formatDoses(product.omicron.dose)[product.omicron.index];
              products.push(temp_product);
              } else if (product.sku!='0000000000' && product.sku!='0') {
              temp_product.name = product.name;
              temp_product.sku = product.sku;
              temp_product.sku_magistral = product.sku_magistral;
              temp_product.quantity = product.quantity;
              temp_product.price = product.price;
              temp_product.priceIVA = product.priceIVA; 
              products.push(temp_product);
              }
            }
            
          });

          this.followup_extended_treatment_plan.forEach(product => {
            let temp_product = {
          name: '',
          sku: '',
          sku_magistral: '',
          quantity: null,
          price: null,
          priceIVA: null, 
          dose: '',
          }
          if(product.quantity>0){
              if(product.has_omicron==1){
              temp_product.name = product.omicron.name;
              temp_product.sku = product.omicron.sku;
              temp_product.sku_magistral = product.omicron.sku;
              temp_product.quantity = product.quantity;
              temp_product.price = JSON.parse(product.omicron.price)[product.omicron.index].toString();
              temp_product.priceIVA = JSON.parse(product.omicron.price_iva)[product.omicron.index].toString();
              temp_product.dose = this.formatDoses(product.omicron.dose)[product.omicron.index];

              products.push(temp_product);
              } else if (product.sku!='0000000000' && product.sku!='0') {
              temp_product.name = product.name;
              temp_product.sku = product.sku;
              temp_product.sku_magistral = product.sku_magistral;
              temp_product.quantity = product.quantity;
              temp_product.price = product.price;
              temp_product.priceIVA = product.priceIVA; 
              products.push(temp_product);
              }
            }
            
          });

          let delivery = {
          name: 'Envio nacional',
          sku: 'DHL',
          sku_magistral: 'DHL',
          quantity: 1,
          price: "150.00",
          priceIVA: "150.00", 
          dose: '',
        }
          products.push(delivery);
          checkout.products = products;
          checkout.total = this.formulas_total;
          checkout.assessment_id = this.assessment.id;

          }else if(mode == 1) {

          
          this.followup_treatment_plan.forEach(product => {
            let temp_product = {
          name: '',
          sku: '',
          sku_magistral: '',
          quantity: null,
          price: null,
          priceIVA: null, 
          dose: '',
          }
          if(product.quantity>0){
              if(product.has_omicron==1 && product.omicron_selected==1){
              temp_product.name = product.omicron.name;
              temp_product.sku = product.omicron.sku;
              temp_product.sku_magistral = product.omicron.sku;
              temp_product.quantity = product.quantity;
              temp_product.price = JSON.parse(product.omicron.price)[product.omicron.index].toString();
              temp_product.priceIVA = JSON.parse(product.omicron.price_iva)[product.omicron.index].toString(); 
              temp_product.dose = this.formatDoses(product.omicron.dose)[product.omicron.index];

              products.push(temp_product);
              } else if (product.sku!='0000000000' && product.sku!='0') {
              temp_product.name = product.name;
              temp_product.sku = product.sku;
              temp_product.sku_magistral = product.sku_magistral;
              temp_product.quantity = product.quantity;
              temp_product.price = product.price;
              temp_product.priceIVA = product.priceIVA; 
              products.push(temp_product);
              }
            }
          });

          this.followup_extended_treatment_plan.forEach(product => {
            let temp_product = {
          name: '',
          sku: '',
          sku_magistral: '',
          quantity: null,
          price: null,
          priceIVA: null, 
          dose: '',
          }
          if(product.quantity>0){
              if(product.has_omicron==1 && product.omicron_selected==1){
              temp_product.name = product.omicron.name;
              temp_product.sku = product.omicron.sku;
              temp_product.sku_magistral = product.omicron.sku;
              temp_product.quantity = product.quantity;
              temp_product.price = JSON.parse(product.omicron.price)[product.omicron.index].toString();
              temp_product.priceIVA = JSON.parse(product.omicron.price_iva)[product.omicron.index].toString(); 
              temp_product.dose = this.formatDoses(product.omicron.dose)[product.omicron.index];

              products.push(temp_product);
              } else if (product.sku!='0000000000' && product.sku!='0') {
              temp_product.name = product.name;
              temp_product.sku = product.sku;
              temp_product.sku_magistral = product.sku_magistral;
              temp_product.quantity = product.quantity;
              temp_product.price = product.price;
              temp_product.priceIVA = product.priceIVA; 
              products.push(temp_product);
              }
            } 
          });

          let delivery = {
          name: 'Envio nacional',
          sku: 'DHL',
          sku_magistral: 'DHL',
          quantity: 1,
          price: "150.00",
          priceIVA: "150.00", 
          dose: '',
        }
          products.push(delivery);
          checkout.products = products;
          checkout.total = this.custom_total;
          checkout.assessment_id = this.assessment.id;

        } else if(mode == 2) {

          this.followup_treatment_plan.forEach(product => {
            let temp_product = {
          name: '',
          sku: '',
          sku_magistral: '',
          quantity: null,
          price: null,
          priceIVA: null, 
          dose: '',
        }
            if(product.quantity>0){
              if (product.sku!='0000000000' && product.sku!='0') {
              temp_product.name = product.name;
              temp_product.sku = product.sku;
              temp_product.sku_magistral = product.sku_magistral;
              temp_product.quantity = product.quantity;
              temp_product.price = product.price;
              temp_product.priceIVA = product.priceIVA; 
              products.push(temp_product);
              }
               else if(product.has_omicron==1){
              temp_product.name = product.omicron.name;
              temp_product.sku = product.omicron.sku;
              temp_product.sku_magistral = product.omicron.sku;
              temp_product.quantity = product.quantity;
              temp_product.price = JSON.parse(product.omicron.price)[product.omicron.index].toString();
              temp_product.priceIVA = JSON.parse(product.omicron.price_iva)[product.omicron.index].toString();
              temp_product.dose = this.formatDoses(product.omicron.dose)[product.omicron.index];

              products.push(temp_product);
              }
            }
            
          });
          
          this.followup_extended_treatment_plan.forEach(product => {
            let temp_product = {
          name: '',
          sku: '',
          sku_magistral: '',
          quantity: null,
          price: null,
          priceIVA: null, 
          dose: '',
        }
        if(product.quantity>0){
              if (product.sku!='0000000000' && product.sku!='0') {
              temp_product.name = product.name;
              temp_product.sku = product.sku;
              temp_product.sku_magistral = product.sku_magistral;
              temp_product.quantity = product.quantity;
              temp_product.price = product.price;
              temp_product.priceIVA = product.priceIVA; 
              products.push(temp_product);
              }
               else if(product.has_omicron==1){
              temp_product.name = product.omicron.name;
              temp_product.sku = product.omicron.sku;
              temp_product.sku_magistral = product.omicron.sku;
              temp_product.quantity = product.quantity;
              temp_product.price = JSON.parse(product.omicron.price)[product.omicron.index].toString();
              temp_product.priceIVA = JSON.parse(product.omicron.price_iva)[product.omicron.index].toString();
              temp_product.dose = this.formatDoses(product.omicron.dose)[product.omicron.index];

              products.push(temp_product);
              }
            }
            
          });

          let delivery = {
          name: 'Envio nacional',
          sku: 'DHL',
          sku_magistral: 'DHL',
          quantity: 1,
          price: "150.00",
          priceIVA: "150.00", 
          dose: '',
        }
          products.push(delivery);
          checkout.products = products;          
          checkout.total = this.pharmacy_total;
          checkout.assessment_id = this.assessment.id;

        }

        try {
            // Make a POST request to your API endpoint to create the assessment
            const response = await axios.post(`${this.$baseURL}/api/create-pharmacy-order`, checkout);
            if (response.status === 200) {
                // If the request was successful, save the new assessment id

                this.$router.push(`/pharmacy-payment-method/${this.assessment.id}`);
               
            } else {
                console.error('Error creating order:', response);
            }
        } catch (error) {
            console.error('Error creating order:', error);
        }
    },

    toggleCustomCart() {
      this.isCustomCartCollapsed = !this.isCustomCartCollapsed;
    },
    toggleFormulasCart() {
      this.isFormulasCartCollapsed = !this.isFormulasCartCollapsed;
    },
    togglePharmacyCart() {
      this.isPharmacyCartCollapsed = !this.isPharmacyCartCollapsed;
    },


    togglePharmacy() {
      this.isPharmacyCollapsed = !this.isPharmacyCollapsed;
    },

    toggleAssessmentsData() {
      this.isAssessmentsDataCollapsed = !this.isAssessmentsDataCollapsed;
    },

      toggleExplanation() {
      this.isExplanationCollapsed = !this.isExplanationCollapsed;
    },
    togglePrognosis() {
      this.isPrognosisCollpased = !this.isPrognosisCollpased;
    },
    toggleFollowup() {
      this.isFollowupCollapsed = !this.isFollowupCollapsed;
    },
    toggleRecommendations() {
      this.isRecommendationsCollapsed = !this.isRecommendationsCollapsed;
    },
    toggleExams() {
      this.isExamsCollapsed = !this.isExamsCollapsed;
    },
    toggleComments() {
      this.isCommentsCollapsed = !this.isCommentsCollapsed;
    },
    toggleTreatment() {
      this.isTreatmentCollapsed = !this.isTreatmentCollapsed;
    },

    ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),

      calculateAge(birthday) {
    const birthdate = new Date(birthday);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },

      parseSex(sex){
        if(sex=='Male'){
          return 'Masculino';
        } else {
          return 'Femenina';
        }
      },

      parseDuration(duration){
        if(duration=='Years'){
          return 'Años';
        } else if(duration=='Months'){
          return 'mes(es)';
        } else if(duration=='Weeks'){
          return 'semana(s)';
        } else if(duration=='Days'){
          return 'dia(s)';
        } else if(duration=='Hours'){
          return 'hora(s)';
        }
      },

      parseDay(day){
        if(day==0){
          return 'Lunes';
        } else if(day==1){
          return 'Martes';
        } else if(day==2){
          return 'Miercoles';
        } else if(day==3){
          return 'Jueves';
        } else if(day==4){
          return 'Viernes';
        } else if(day==5){
          return 'Sabado';
        } else if(day==6){
          return 'Domingo';
        }
      },

async getAssessmentFiles(){
  const token = localStorage.getItem('Token');
  try {
    const response = await axios.get(`${this.$baseURL}/api/get-files`, {
      params: {
        assessment_id: this.assessment.id,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    const imageFiles = response.data.data.filter(file => file.file_type === 0);
    const examFiles = response.data.data.filter(file => file.file_type === 1);

     this.assessment.images = imageFiles;
     this.assessment.exams = examFiles;
} catch (error) {
    console.error('Error fetching assessment files:', error);
  }
},

async getDiagnosisTreatment(){
  const token = localStorage.getItem('Token');
  try {
    const response = await axios.get(`${this.$baseURL}/api/get-treatments`, {
      params: {
        diagnosis_id: this.diagnosis.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    });

     this.treatment = response.data.data;

    this.treatment.forEach(product => {
      product.quantity = 1;
      if(product.is_minimal == 0) {
        this.extended_treatment_plan.push(product);
      } else {
        this.treatment_plan.push(product);
      }
      
     });
} catch (error) {
    console.error('Error fetching treatment data:', error);
  }
},   

async getFollowupDiagnosisTreatment(){
  const token = localStorage.getItem('Token');
  try {
    const response = await axios.get(`${this.$baseURL}/api/get-followup-treatments`, {
      params: {
        followup_diagnosis_id: this.followup.id,
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    });

     this.treatment = response.data.data;
    this.treatment.forEach(product => {
      product.quantity = 1;
      product.omicron_selected = 1;
      if(product.has_omicron==1){
        product.omicron=JSON.parse(product.omicron)
      }
      if(product.is_minimal == 0) {
        this.followup_extended_treatment_plan.push(product);
      } else {
        this.followup_treatment_plan.push(product);
      }
      
     });
} catch (error) {
    console.error('Error fetching followup treatment data:', error);
  }
},  
    },

  };


  </script>
  
  <style scoped>

.assessment-image {
    width: auto;    /* Set the width of the image to 40% of the container */
    max-height: 60vh;  /* Maintain the aspect ratio */
    max-width: 100%;
    display: block;
    object-fit: cover; /* This will ensure that your images are always centered and cover the element. It might crop some parts of the image if the aspect ratios do not match */
    margin: 0 auto; /* Center the image if necessary */
}


.header {
    margin-top: -5px;
    position: absolute;
    width: 100%;
    height: 60px;
    background: #36b5ff;
    z-index: 1;
  }

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}


.fa-star {
  color: gray;
  cursor: pointer;
}

.fa-star.active {
  color: gold;
}

textarea {
  width: 100%;
  margin-top: 10px;
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z'/></svg>") no-repeat right center;
  background-size: 20px; /* Adjust based on your icon size */
  padding-right: 30px; /* Adjust based on your icon size */
}

  </style>
  