<template>
    <div>
      <header class="header d-flex align-items-center justify-content-between" style="width: 100%; padding: 20px;">
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:60px;"/>
<!--         <nav>
          <button class="nav-item">Puntos: {{ this.credits }}</button>
        </nav> -->
      </header>
      <div class="container">
        <div class="row justify-content-center" >
          <div class="col-md-8" style="margin-top: 60px;">
            <div class="card mb-2">
              <div class="card-body">
                <h5 class="card-title">Efectivo en Oxxo</h5>
                <p class="card-text" style="text-align: left;">
                  1. Haz click en el boton de "Generar codigo de barras".<br>
                  2. Recibirás un código de barras que puedes mostrar en caja para pagar. <br>
                  3. Acude al Oxxo mas cercano y completa el pago.<br>
                  4. Toma una foto del recibo y da clic en el boton.<br>
                  5. Envia el comprobante a la plataforma dando clic en el boton.<br>
                  6. Tambien puedes enviarlo por correo a soporte@dermapp.com o por whatsapp al +528140052978. <br><br>
                  Nota: Al pagar por transferencia puede tomar hasta 48 horas en verse reflejado el pago. Si pagaste y tu pago no se actualiza a la brevedad, no dudes en contactarnos para ayudarte!
                </p>
                <button @click="generatePayment" class="btn btn-primary">Generar pago</button>
                <div class="form-group" style="margin-top: 20px;">
                  <label for="images">Sube tu comprobante!</label>
                  <input type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="updateReceiptFile">
                </div>
                <div>
                  <button v-if="receiptFile" @click="uploadReceipt" class="btn btn-primary">Subir comprobante</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { loadStripe } from '@stripe/stripe-js';
  import { mapState } from 'vuex';
  import axios from 'axios';
  import { v4 as uuidv4 } from 'uuid';
  
  
  export default {
    data() {
      return {
        assessment_id: null,
        HeaderLogo: require('@/assets/dermapp-white.webp'),
        receiptFile: null
      };
    },
    computed: {
      ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex']),
    },
    mounted() {
    this.assessment_id = this.$route.params.id;
  },
  methods: {
    async generatePayment() {
      // Here, we need to make a POST request to your server to create the PaymentIntent
      // The server will respond with a client_secret that we will use in the next step
      // The exact implementation of this will depend on your server architecture
      // The following is a sample placeholder implementation
      const details = {
        amount: 180,
      }

      const response = await axios.post(`${this.$baseURL}/api/stripe-oxxo-intent`, details);

      const clientSecret = response.data.client_secret;

      const stripe = await loadStripe('pk_test_XDTNp2Ay33oaBWSp3hzK6tOI'); // replace 'pk_test_12345' with your public Stripe key

      await stripe.confirmOxxoPayment(clientSecret, {
        payment_method: {
          billing_details: {
            email: this.email,
            name: this.name,// Add the necessary billing details
          },
        },
      });

    },
    updateReceiptFile(event) {
        this.receiptFile = event.target.files[0];
    },

    async uploadToS3(file, folder) {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
  const fileName = `${folder}${newFileName}`;
  const destination = 'dermapp-documents';
  const availability = 'public-read';

  const formData = new FormData();
  formData.append('destination', destination);
  formData.append('fileName', fileName);
  formData.append('file', file);
  formData.append('availability', availability);

  try {
    await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    await this.uploadToDB(newFileName, fileExtension);
  } catch (error) {
    console.error('Error uploading files to s3:', error);
  }
},

async uploadToDB(fileName, fileExtension){
  let fileType = 2;
      
      try {
        const files_data = {
          assessment_id: this.$route.params.id,
          file_name: fileName,
          file_extension: fileExtension,
          file_type: fileType,
        }


await axios.post(`${this.$baseURL}/api/assessment-files`, files_data);


} catch (error) {
console.error('Error creating assessment:', error);
}
},

async uploadReceipt() {
        try {
            // Make a POST request to your API endpoint to create the assessment

                // Now that we have an assessmentId, we can upload the files
                const images = this.$refs.imagesRef.files;
                for(let i=0; i<images.length; i++) {
                    this.uploadToS3(images[i], 'receipts/');
                }

                this.$router.push({ name: 'PatientProfile', params: { id: this.profileId} });
            
            }
        catch (error) {
            console.error('Error uploading receipt:', error);
        }
    },
    }
  };
</script>

<style scoped>
.header {
  margin-top: -5px;
  position: absolute;
  width: 100%;
  height: 60px;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
</style>
  