<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
      </div>
    </header>
        
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isDataLoaded" class="col-md-8">
          <div class="card mb-2">
            <div class="card-body">
              <h5>Atención a clientes</h5>
              <p>Este chat es para ayuda o dudas del servicio</p>    
              <div class="card" style="height:70vh; overflow-y: auto; overflow-x:hidden">
                <div class="message" v-for="(msg, index) in user.messages" :key="index"
                     :class="{'admin-message': msg.sender === 'admin', 'user-message': msg.sender === 'user'}"
                     @click="toggleDate(index)">
                  <p>{{ msg.message }}</p>
                  <small v-if="visibleDateIndex === index">{{ msg.date }}</small>
                </div>
              </div>
              <div class="input-group mt-3">
                <input type="text" class="form-control" id="new_message" v-model="newMessage" placeholder="Escribe un mensaje...">
                <button class="btn btn-primary" type="button" @click="sendMessage">Enviar</button>
              </div>
            </div>
          </div>                
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { mapState } from 'vuex';
import axios from 'axios';
import useFacebookPixel from '@/composables/useFacebookPixel';
import { onMounted } from 'vue';

export default {
  setup() {
    const { track } = useFacebookPixel();

    

    onMounted(() => {
      track('ViewContent', { content_name: 'User Messages' });

    });

    return {};
  },
    
  data() {
    return {
      newMessage: "",
      isDataLoaded: false,
      HeaderLogo: require('@/assets/dermapp-white.png'),
      user: [],
      visibleDateIndex: null // Track the index of the message with a visible date
    };
  },
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday', 'profileSubscription']),
  },
  mounted() {

    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          console.log("Usuario autenticado");
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token
          console.log('Token renovado:', idToken);

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            console.log('Token expirado, redirigiendo al login.');
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        console.log('No hay un usuario autenticado.');
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

    const token = localStorage.getItem('Token');
    axios.get(`${this.$baseURL}/api/user-data-id`, {
      params: {
        user_id: this.$route.params.id,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      this.user = response.data.data;
      this.user.messages = JSON.parse(this.user.messages);
      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
  },
  methods: {
    async sendMessage() {
      if (this.newMessage.trim() !== '') {
        if (!this.user.messages) {
          this.user.messages = [];
        }
        const now = new Date(); // Create a new Date object to get the current date and time
        this.user.messages.push({ sender: 'user', message: this.newMessage, date: now.toLocaleString() });
        
        let updatedMessages = {
          user_id: this.$route.params.id,
          messages: this.user.messages,
          date: now.toLocaleString()
        }
        
        this.newMessage = '';
        await axios.put(`${this.$baseURL}/api/update-user-messages`, updatedMessages);
      }
    },
    async markAsRead() {
      this.user.new_message = 0;
      let updatedMessages = {
        user_id: this.user.id,
      }
      await axios.put(`${this.$baseURL}/api/update-user-read-message`, updatedMessages);
    },
    toggleDate(index) {
      if (this.visibleDateIndex === index) {
        this.visibleDateIndex = null; // Hide the date if the same message is clicked again
      } else {
        this.visibleDateIndex = index; // Show the date for the clicked message
      }
    }
  },
}
</script>
  
<style scoped>
.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.message {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
}

.user-message {
  text-align: left;
  margin-right: 10px;
  margin-left: 60px;
  background-color: #d0e0f0;
}

.admin-message {
  text-align: left;
  margin-right: 60px;
  margin-left: 10px;
  background-color: #b9b9b9;
}
</style>
