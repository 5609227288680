<template>
  <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
    <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
    </div>
    <nav class="d-flex align-items-center justify-content-end" style="width: 100%; text-align:right">
      <button 
        class="nav-item" 
        style="padding-left:10px; padding-right:10px; height:40px;width:auto;margin-right:20px;border: 2px solid white;border-radius: 10px; background: none; color: white; cursor: pointer;" 
        @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
        Créditos: {{ this.credits }}
      </button> 
    </nav>
  </header>
  <div class="container" style="height: 100vh;">
    <div class="card-container" style="display: flex; justify-content: center; align-items: center;">
      <div class="card" style="width: 100%; height: 100%;">
        <h5 class="text-center mb-2 mt-4">¡Tu pago ha sido <br>recibido exitosamente!</h5>
        <div class="card-body">
          <p style="text-align: left;">Uno de nuestros dermatólogos revisará tu caso en menos de 24 horas.</p>
          <p style="text-align: left;">Tu consulta incluye:</p>
            <div style="display: flex; justify-content: center;">
              <div style="text-align: left;">
                <ul>
                  <li>Diagnóstico</li>
                  <li>Explicación del problema</li>
                  <li>Explicación del pronóstico</li>
                  <li>Explicación sobre el seguimiento</li>
                  <li>Recomendaciones generales</li>
                  <li>Plan de tratamiento</li>
                </ul>
              </div>
            </div>              
            <p style="text-align: left;">
              También podrás pedir tus productos a cualquier parte de México y ahorrar hasta un 50% con fórmulas magistrales.
            </p>
            <strong style="text-align: center;">¡Te enviaremos los resultados pronto!</strong>

        <router-link to="/patient-dashboard" class="btn btn-primary" style="margin-top: 20px; width:80%">Finalizar</router-link>
      </div>
    </div>
  </div>
    </div>
    
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapActions, mapState } from 'vuex';
import useFacebookPixel from '@/composables/useFacebookPixel';
import { onMounted } from 'vue';
import axios from 'axios';

export default {
  setup() {

    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', { content_name: 'Payment Success' });
      track('Purchase', { value: 180.00, currency: 'MXN', content_name: 'Payment Success' });

      
    });
 

    return {};
  }, 
  data() {
    return {
      nextChargeDate: '',
      HeaderLogo: require('@/assets/dermapp-white.png')

    };
  },
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday']),
    },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          console.log("Usuario autenticado");
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token
          console.log('Token renovado:', idToken);

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            console.log('Token expirado, redirigiendo al login.');
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        console.log('No hay un usuario autenticado.');
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });

    const token = localStorage.getItem('Token');
    axios.get(`${this.$baseURL}/api/user-data-v2`, {
      params: {
        email: this.email || this.$route.query.email,
        page: 'Payment Success'
      },
      headers: {
          'Authorization': `Bearer ${token}`
        }
    })
    .then((response) => {
    const data = response.data.data;
    this.updateUserId(data.id);
    this.updateEmail(data.email);
    this.updatePhone(data.phone, data.country);
    this.updateCountry(data.country);
    this.updateCredits(data.credits);
    this.updateReferralCode(data.referral_code);
  })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
  },
  methods: {
    ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription',  ]),
  },
};
</script>

<style scoped>
.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}
.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

</style>
